export const environment = {
  production: true,
  api: (() => {
    const hostname = location.hostname;
    if (hostname === 'romaniaexpress.app') {
      return 'https://api.romaniaexpress.app/api/';
    } else if (hostname === 'sandbox.romaniaexpress.app') {
      return 'https://sandbox-api.romaniaexpress.app/api/';
    } else if (hostname === 'romaniaexpress.dev') {
      return 'https://api.romaniaexpress.dev/api/';
    }
  })()
};
